import { CountryCode, DeployPage } from "config/BaseConfig";
import React from "react";
import MiiTelCallCenterLogoJP from "../../../assets/img/MiiTel_CallCenter_logo.png";
import MiiTelRecPodLogoJP from "../../../assets/img/MiiTel_RecPod_logo.png";
import MiiTelLogoJP from "../../../assets/img/MiiTel_Meetings_logo.png";
import MiiTelLogoUS from "../../../assets/img/logo_miitel.svg";
import classes from "./Header.module.scss";

interface Props {}

const Header: React.FC<Props> = () => {
  return (
    <header className={classes["main_css_" + CountryCode] + " header"}>
      <div className="header_logo-container js-anchor">
        <a
          className={classes["logo_css_" + CountryCode] + " header_logo"}
          href={
            CountryCode === "US"
              ? "https://miitel.com/revcomm-us/"
              : DeployPage === "DOCUMENT_REQUEST_CC" ||
                DeployPage === "DEMO_REQUEST_CC"
              ? "https://miitel.com/jp/callcenter"
              : DeployPage === "DOCUMENT_REQUEST_RECPOD"
              ? "https://miitel.com/jp/recpod"
              : "https://miitel.com/jp/meetings"
          }
        >
          <img
            src={
              CountryCode === "US"
                ? MiiTelLogoUS
                : DeployPage === "DOCUMENT_REQUEST_CC"
                ? MiiTelCallCenterLogoJP
                : DeployPage === "DEMO_REQUEST_CC" || DeployPage === "DEMO_REQUEST"
                ? MiiTelLogoUS
                : DeployPage === "DOCUMENT_REQUEST_RECPOD"
                ? MiiTelRecPodLogoJP
                : MiiTelLogoJP
            }
            alt=""
            width={
              CountryCode === "US"
                ? 100
                : DeployPage === "DOCUMENT_REQUEST_CC"
                ? 317
                : 200
            }
            height={DeployPage === "DOCUMENT_REQUEST_RECPOD" ? 34 : 25}
            data-pagespeed-url-hash="2434161800"
          />
        </a>
      </div>
    </header>
  );
};

export default Header;
